import { formatPrice } from '@thg-commerce/enterprise-pricing/src'

interface Price {
  price?: { displayValue: string }
  rrp?: { displayValue: string }
  saving?: number
}

export const getPrice = (
  selectedVariant: {
    price?: Price | null
    priceRange?: string | null
    rrpRange?: string | null
  },
  defaultCurrency?: string,
  defaultLocale?: string,
) => {
  const getDisplayValue = (value?: string) => value || '0.00'

  const price = getDisplayValue(selectedVariant.price?.price?.displayValue)
  const rrp = getDisplayValue(selectedVariant.price?.rrp?.displayValue)

  const saving =
    defaultCurrency && defaultLocale && selectedVariant.price?.saving
      ? formatPrice(
          defaultCurrency,
          selectedVariant.price?.saving,
          defaultLocale,
        )
      : undefined

  return {
    saving,
    price: selectedVariant.priceRange || price,
    rrp: selectedVariant.rrpRange
      ? selectedVariant.rrpRange
      : selectedVariant.priceRange
      ? ''
      : rrp,
  }
}
