import * as React from 'react'

import { breakpointUtils } from '@thg-commerce/gravity-theme/breakpoints'

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = React.useState('xs')

  const queries = {
    xs: `(max-width: ${breakpointUtils.map.sm}px)`,
    sm: `(min-width: ${breakpointUtils.map.sm}px) and (max-width: ${breakpointUtils.map.md}px)`,
    md: `(min-width: ${breakpointUtils.map.md}px) and (max-width: ${breakpointUtils.map.lg}px)`,
    lg: `(min-width: ${breakpointUtils.map.lg}px)`,
  }

  React.useEffect(() => {
    const updateBreakpoint = (event: MediaQueryListEvent) => {
      const newBreakpoint = Object.keys(queries).find(
        (objectKey) => queries[objectKey] === event.media,
      )

      if (event.matches && newBreakpoint) {
        setBreakpoint(newBreakpoint)
      }
    }

    const mediaQueryList = breakpointUtils.keys.map((breakpointKey) => {
      const mediaQuery = window.matchMedia(queries[breakpointKey])

      if (mediaQuery.matches) {
        setBreakpoint(breakpointKey)
      }

      mediaQuery.addEventListener('change', updateBreakpoint)

      return mediaQuery
    })

    return () => {
      mediaQueryList.forEach((mediaQuery) => {
        mediaQuery.removeEventListener('change', updateBreakpoint)
      })
    }
  }, [])

  return breakpoint
}
