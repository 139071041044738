import * as React from 'react'

import { spacing, styled, Text } from '@thg-commerce/gravity-theme'
import { Button } from '@thg-commerce/gravity-elements'

interface Colors {
  textColor?: string
  tagColor?: string
}

const Tag = styled.span<{
  tagColor?: Colors['tagColor']
  textColor?: Colors['textColor']
}>`
  ${Text('bodyText', 'default')};
  width: auto;
  display: flex;
  padding: ${spacing(0.25)} ${spacing(1)};
  margin-right: ${spacing(1)};
  margin-bottom: ${spacing(1)};
  background: ${(props) =>
    props.tagColor || props.theme.colors.palette.greys.lighter};
  border-radius: 12px;
  ${(props) => props.textColor && `color: ${props.textColor}`};
`

const StyledButton = styled(Button)`
  padding: ${spacing(0.375)};

  &:focus,
  &:active {
    padding: ${spacing(0.375)};
    border: 1px solid transparent;
  }
`

export interface TagsProps {
  tags: string[]
  colors?: Colors
  tagButton?: {
    onClick: (groupingTag: string, index: number) => void
    text?: string
    icon?: React.ReactElement
  }
  className?: string
}

export const Tags = (props: TagsProps) => {
  return (
    <div className={props.className}>
      {props.tags.map((groupingTag, index) => (
        <Tag
          key={groupingTag}
          tagColor={props.colors?.tagColor}
          textColor={props.colors?.textColor}
        >
          {groupingTag}

          {props.tagButton && (
            <StyledButton
              emphasis="low"
              style={{
                marginLeft: spacing(0.5),
              }}
              onClick={() => {
                if (props.tagButton) {
                  props.tagButton.onClick(groupingTag, index)
                }
              }}
            >
              {props.tagButton.text}
              {props.tagButton.icon}
            </StyledButton>
          )}
        </Tag>
      ))}
    </div>
  )
}
