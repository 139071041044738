import * as React from 'react'
import dynamic from 'next/dynamic'

import { useTheme } from '@thg-commerce/gravity-elements/theme'
import { i18n } from '@thg-commerce/enterprise-core'
import { spacing } from '@thg-commerce/enterprise-theme'
import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { SelectedOptions } from '@thg-commerce/gravity-patterns'

import {
  RoundCheckbox,
  StyledInput,
  SubscriptionCard,
  SubscriptionPricing,
  SubscriptionSaving,
  SubscriptionsWrapper,
  SubscriptionTitle,
} from './styles'

const OneWeekSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/OneWeek'
    ),
)

const TwoWeeksSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/TwoWeeks'
    ),
)

const ThreeWeeksSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/ThreeWeeks'
    ),
)

const OneMonthSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/OneMonth'
    ),
)

const TwoMonthsSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/TwoMonths'
    ),
)

const ThreeMonthsSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/ThreeMonths'
    ),
)

const FourMonthsSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/FourMonths'
    ),
)

const SixMonthsSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/SixMonths'
    ),
)

const TwelveMonthsSubscriptionIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SubscriptionIcons/TwelveMonths'
    ),
)

const CheckmarkIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Checkmark'),
)

interface ProductVariationsProps {
  subscriptionChoices: SubscriptionChoice[]
  selectedOptions?: SelectedOptions
  onOptionChange?: (key: string, value: string) => void
  showSavingsAmountOnSubscriptionCard?: boolean
  subscriptionChoicesPrefixId: String
}

export enum SubscriptionPaymentType {
  UPFRONT = 'Upfront',
  ON_DISPATCH = 'On_Dispatch',
  ON_ORDER = 'On_Order',
}

export enum SubscriptionType {
  ONE_WEEK = '1 Week',
  TWO_WEEKS = '2 Weeks',
  THREE_WEEKS = '3 Weeks',
  ONE_MONTH = '1 Month',
  TWO_MONTHS = '2 Months',
  THREE_MONTHS = '3 Months',
  THREE_MONTH = '3 Month',
  FOUR_MONTHS = '4 Months',
  SIX_MONTHS = '6 Months',
  SIX_MONTH = '6 Month',
  TWELVE_MONTHS = '12 Months',
  TWELVE_MONTH = '12 Month',
}

const getSubscriptionIcon = (subscriptionType: SubscriptionType) => {
  switch (subscriptionType) {
    case SubscriptionType.ONE_WEEK:
      return <OneWeekSubscriptionIcon width="30" height="32" />
    case SubscriptionType.TWO_WEEKS:
      return <TwoWeeksSubscriptionIcon width="30" height="32" />
    case SubscriptionType.THREE_WEEKS:
      return <ThreeWeeksSubscriptionIcon width="30" height="32" />
    case SubscriptionType.ONE_MONTH:
      return <OneMonthSubscriptionIcon width="30" height="32" />
    case SubscriptionType.TWO_MONTHS:
      return <TwoMonthsSubscriptionIcon width="30" height="32" />
    case SubscriptionType.THREE_MONTHS:
    case SubscriptionType.THREE_MONTH:
      return <ThreeMonthsSubscriptionIcon width="30" height="32" />
    case SubscriptionType.FOUR_MONTHS:
      return <FourMonthsSubscriptionIcon width="30" height="32" />
    case SubscriptionType.SIX_MONTHS:
    case SubscriptionType.SIX_MONTH:
      return <SixMonthsSubscriptionIcon width="30" height="32" />
    case SubscriptionType.TWELVE_MONTHS:
    case SubscriptionType.TWELVE_MONTH:
      return <TwelveMonthsSubscriptionIcon width="30" height="32" />
    default:
      return null
  }
}

export const ProductVariations = (props: ProductVariationsProps) => {
  const theme = useTheme()

  const i18nText = { saving: i18n('general.save.text') }

  return (
    <SubscriptionsWrapper>
      {props.subscriptionChoices.map(
        (choice: SubscriptionChoice, index: Number) => {
          const isSelected = props.selectedOptions?.Option === choice.key
          const optionName = `${props.subscriptionChoicesPrefixId}-option-${index}-name`
          const optionPrice = `${props.subscriptionChoicesPrefixId}-option-${index}-price`

          return (
            <SubscriptionCard
              isSelected={isSelected}
              showSavingsAmountOnSubscriptionCard={
                !!(props.showSavingsAmountOnSubscriptionCard && choice.saving)
              }
            >
              <StyledInput
                data-testid={`${choice.key}-toggle-input`}
                type="radio"
                checked={isSelected}
                value={choice.key}
                onChange={(event) => {
                  props.onOptionChange &&
                    props.onOptionChange(choice.optionKey, event.target.value)
                }}
                aria-labelledby={`${optionName} ${optionPrice}`}
                name={`Choose a subscription option`}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ paddingTop: spacing(1) }}>
                  {getSubscriptionIcon(choice.key as SubscriptionType)}
                </div>
                <SubscriptionTitle id={optionName}>
                  {choice.title}
                </SubscriptionTitle>
                <SubscriptionPricing id={optionPrice}>
                  {choice.price}
                </SubscriptionPricing>
                {props.showSavingsAmountOnSubscriptionCard && choice.saving && (
                  <SubscriptionSaving>
                    {i18nText.saving}: {choice.saving}
                  </SubscriptionSaving>
                )}
                {isSelected ? (
                  <CheckmarkIcon
                    style={{ color: `${theme.colors.palette.brand.base}` }}
                  />
                ) : (
                  <RoundCheckbox />
                )}
              </div>
            </SubscriptionCard>
          )
        },
      )}
    </SubscriptionsWrapper>
  )
}
