import { VariantFields as TransformerVariant } from '@thg-commerce/enterprise-components/src/transformer/productOptionsTransformer'

export interface BasketItem {
  product: {
    sku: string
  }
  quantity: number
}

export interface BasketData {
  items: BasketItem[]
}

const getCurrentProductFromBasket = (
  basket: BasketData,
  selectedVariant?: TransformerVariant,
): number => {
  const matchedBasketItem = basket?.items?.find(
    (basketItem: BasketItem) =>
      basketItem?.product.sku === selectedVariant?.sku,
  )
  return matchedBasketItem?.quantity ?? 0
}

export const getMaxQuantity = (
  basket?: BasketData,
  selectedVariant?: TransformerVariant,
) => {
  if (!selectedVariant) return { maxQuantity: 99, quantityInBasket: 0 }
  if (!basket) {
    return {
      maxQuantity: selectedVariant.maxPerOrder || 99,
      quantityInBasket: 0,
    }
  }

  const quantityInBasket = getCurrentProductFromBasket(basket, selectedVariant)
  const maxPerOrder = selectedVariant.maxPerOrder || 99
  return { quantityInBasket, maxQuantity: maxPerOrder - quantityInBasket }
}
