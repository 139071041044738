import * as React from 'react'

import {
  Box,
  ItemWrapper,
  MainContentWrapper,
  PriceQuantityWrapper,
  PriceWrapper,
  SelectionDescriptionWrapper,
  Square,
  Swatch,
  SwatchWrapper,
} from './styles'

export const QuickBuyModalSkeleton = () => {
  const buildSwatches = (count: number) => {
    return [...Array(count)].map((_, i) => <Swatch key={i} />)
  }
  return (
    <React.Fragment>
      <Box width="50%" marginTop={2} marginBottom={2} height="32px" />
      <MainContentWrapper>
        <Square />
        <SelectionDescriptionWrapper>
          <ItemWrapper>
            <Box marginTop={2} height="32px" />
            <Box width="75%" marginTop={1} height="32px" />
            <Box width="50%" marginTop={3} height="18px" />
            <Box marginTop={1} height="32px" />
          </ItemWrapper>
          <SwatchWrapper>{buildSwatches(10)}</SwatchWrapper>
          <Box marginTop={3} height="1px" />
          <PriceQuantityWrapper>
            <PriceWrapper>
              <Box height="18px" />
              <Box marginTop={1} height="32px" />
              <Box marginTop={1} height="18px" />
            </PriceWrapper>
            <Box width="36%" height="48px" />
          </PriceQuantityWrapper>
          <Box marginTop={2} height="48px" />
          <Box marginTop={2} height="18px" />
          <Box marginTop={3} height="48px" />
        </SelectionDescriptionWrapper>
      </MainContentWrapper>
    </React.Fragment>
  )
}
