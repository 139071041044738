import { Skeleton } from '@thg-commerce/gravity-elements'
import { mq, spacing, styled } from '@thg-commerce/gravity-theme'

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing(2)} 0;
`

export const Box = styled(Skeleton)<{
  height: string
  marginTop?: number
  marginBottom?: number
  width?: string
}>`
  width: ${(props) => props.width || '100%'};
  margin-top: ${(props) => spacing(props.marginTop || 0)};
  margin-bottom: ${(props) => spacing(props.marginBottom || 0)};
  height: ${(props) => props.height};
`

export const swatchGap = spacing(0.5)

export const SwatchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${swatchGap} 0 0 -${swatchGap};
`

export const Swatch = styled(Skeleton)`
  width: 48px;
  height: 48px;
  margin: ${swatchGap} 0 0 ${swatchGap};
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 28%;
  margin-top: ${spacing(3)};
`

export const PriceQuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
  }
`

export const SelectionDescriptionWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-left: ${spacing(4)};
  }
`

export const Square = styled(Skeleton)`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    height: 190px;
    padding-bottom: 0;
    flex: 0 0 190px;
  }
`
