import { styled, Text, spacing } from '@thg-commerce/enterprise-theme'

export const SubscriptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing(2)};
`

export const SubscriptionCard = styled.label<{
  isSelected: boolean
  showSavingsAmountOnSubscriptionCard: boolean
}>`
  position: relative;
  border: 4px solid
    ${(props) =>
      props.isSelected
        ? props.theme.colors.palette.brand.base
        : props.theme.colors.palette.accent.base};
  max-width: 120px;
  width: 100%;
  height: ${(props) =>
    props.showSavingsAmountOnSubscriptionCard ? '280px' : '240px'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing(2)};

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }

  &:last-child {
    margin-right: 0;
  }
`

export const SubscriptionTitle = styled.h2`
  ${Text('medium1', 'alternate')};
  text-align: center;
  word-spacing: 100vw;
  padding: ${spacing(1)} 0 ${spacing(2)} 0;
  color: ${(props) => props.theme.colors.palette.greys.darker};
`

export const SubscriptionPricing = styled.div`
  text-align: center;
  ${Text('bodyText', 'default')};
  color: ${(props) => props.theme.colors.palette.greys.white};
  background: ${(props) => props.theme.colors.palette.brand.base};
  border: 1px solid ${(props) => props.theme.colors.palette.brand.base};
  border-radius: 35px;
  padding: ${spacing(1)};
`

export const SubscriptionSaving = styled.div`
  text-align: center;
  ${Text('medium1', 'default')};
  color: ${(props) => props.theme.colors.palette.brand.base};
  background: ${(props) => props.theme.colors.palette.greys.light};
  padding: ${spacing(0.5)} ${spacing(1)};
`

export const RoundCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  appearance: none;
  outline: none;
  cursor: pointer;
  margin: ${spacing(1.25)} 0;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  width: inherit;
  height: inherit;
`
