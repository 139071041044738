import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

export const quickBuyModalDataTrackingCallbacks = {
  modalClicked: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked',
        eventCategory: 'Product | quickBuy',
      },
    })
  },
  modalViewed: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Viewed',
        eventCategory: 'Product | quickBuy',
      },
    })
  },
  modalAddToBasket: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | quickBuy',
      },
    })
  },
  modalClose: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | quickBuy',
        eventLabel: 'close modal',
      },
    })
  },
  modalInformation: (productSku: string) => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'clicked',
        eventCategory: 'Product | quickBuyMoreInformation',
        eventLabel: productSku,
      },
    })
  },
}
